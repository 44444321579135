import React, { useRef } from 'react';
import './contact.css';
import { MdOutlineMail } from 'react-icons/md';
import { BsWhatsapp } from 'react-icons/bs';
import emailjs from 'emailjs-com';
import { TailSpin } from 'react-loader-spinner';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { BiLogoLinkedin } from "react-icons/bi";

const Contact = () => {
  const form = useRef();
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    setDisabled(true);
    setLoading(true);

    emailjs
      .sendForm(
        'service_xnjv7ip',
        'template_gs4arrh',
        form.current,
        'Cfo4Ig-hyDJtKuX-j'
      )
      .then(
        (result) => {
          toast(`Thank your for messaging me, I Will get back to you shortly!`, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            type: 'info',
            icon: '✅',
          });
          setLoading(false);
          setDisabled(false);
          console.log(result.text);
          e.target.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <section id="contact">
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <MdOutlineMail className="contact__option-icon" />
            <h4>Email</h4>
            <h5>ihonore01@gmail.com</h5>
            <a
              href="mailto:ihonore01@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              Send a message
            </a>
          </article>
          <article className="contact__option">
            <BiLogoLinkedin className="contact__option-icon" />
            <h4>LinkedIn</h4>
            <a
              href="https://linkedin.com/in/ihonore"
              target="_blank"
              rel="noreferrer"
            >
              Connect on LinkedIn
            </a>
          </article>
          {/* <article className="contact__option">
            <RiMessengerLine className="contact__option-icon" />
            <h4>Messenger</h4>
            <h5>ihonore01@gmail.com</h5>
            <a href="https://m.me/ihonore01" target="_blank" rel="noreferrer">
              Send a message
            </a>
          </article> */}
          {/* <article className="contact__option">
            <BsWhatsapp className="contact__option-icon" />
            <h4>WhatsApp</h4>
            <h5>+33783491124</h5>
            <a
              href="https://wa.me/33783491124"
              target="_blank"
              rel="noreferrer"
            >
              Send a message
            </a>
          </article> */}
        </div>
        {/* END OF CONTACT OPTIONS */}
        <form ref={form} onSubmit={sendEmail}>
          <input
            type="text"
            name="name"
            placeholder="Your Full Name"
            required
          />
          <input type="email" name="name" placeholder="Your Email" required />
          <textarea
            name="message"
            rows="7"
            placeholder="Your Message"
            required
          />
          <button
            type="submit"
            className="btn btn-primary"
            style={{ display: 'flex' }}
            disabled={disabled}
          >
            {loading && <TailSpin width="1rem" height="1rem" color="#2c2c6c" />}
            <span style={{ marginLeft: '0.5rem' }}>Send Message</span>
          </button>
        </form>
      </div>
    </section>
  );
};

export default Contact;
