import React from 'react';
import './portfolio.css';
import PORTFOLIO from '../../assets/Portfolio-.jpg';
import BAREFOOT from '../../assets/BN-Mockup1.jpg';
import FLASHCARDS from '../../assets/FC-MOCKUP.jpg';
import MYKIBO from '../../assets/mykibo.jpg';
import PRESTIGE from '../../assets/prestige.jpg';
import AZOMCO from '../../assets/azomco.jpg';
import LADRILLO from '../../assets/ladrillo.jpg';

const data = [
  {
    id: 1,
    image: PORTFOLIO,
    title: 'My first Portifolio site, Frontend, backend & Admin dashboard',
    github: 'https://github.com/ihonore/my-brand',
    demo: 'https://ihonore.netlify.app',
    type: 'Github',
  },
  {
    id: 2,
    image: BAREFOOT,
    title: 'Corporate Travel Management System: Streamlining Employee Trips for Global Organizations',
    github: 'https://github.com/ihonore/barefoot-nomad-fe',
    demo: 'https://elites-barefoot-fe-git-dev-elites-team.vercel.app/',
    type: 'Github',
  },
  {
    id: 3,
    image: FLASHCARDS,
    title: ' Flashcards web app with GraphQL and TypeScript',
    github: 'https://github.com/ihonore/flashcards-frontend-graphql',
    demo: 'https://ihonore-flashcards.vercel.app',
    type: 'Github',
  },
  {
    id: 6,
    image: MYKIBO,
    title: 'MyKibo Marketplace: Connecting Africa’s Agri-Businesses with Global Markets ',
    github: 'https://mykibo.com',
    demo: 'https://mykibo.com',
    type: 'Wordpress',
  },
  {
    id: 4,
    image: PRESTIGE,
    title: 'Prestige Travels Car Rental and Tour guide Website',
    github: 'http://prestigetravel.rw/',
    demo: 'http://prestigetravel.rw/',
    type: 'Wordpress',
  },
  {
    id: 5,
    image: AZOMCO,
    title: 'Africa Basketball Players Database',
    github: 'https://azomcoglobal.com/',
    demo: 'https://azomcoglobal.com/',
    type: 'Wordpress',
  },
  {
    id: 7,
    image: LADRILLO,
    title: 'Ladrillo Interiors: A Website for a Interior Design Firm',
    github: 'https://ladrillointeriors.rw/',
    demo: 'https://ladrillointeriors.rw/',
    type: 'Wordpress',
  }
];

const Portfolio = () => {
  return (
    <section id="portfolio">
      <h5>Some of the projects I worked on</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {data.map(({ id, image, title, github, demo, type }) => {
          return (
            <article className="portfolio__item" key={id}>
              <div className="portfolio__item-image">
                <img src={image} alt={title} />
              </div>
              <h3>{title}</h3>
              <div className="portfolio__item-cta">
                {type === 'Github' ? (
                  <>
                    <a
                      href={github}
                      className="btn"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Github
                    </a>
                    <a
                      href={demo}
                      className="btn btn-primary"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Live Demo
                    </a>
                  </>
                ) : (
                  <a
                    href={demo}
                    className="btn full-button"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Visit Website
                  </a>
                )}
              </div>
            </article>
          );
        })}
      </div>
    </section>
  );
};

export default Portfolio;


