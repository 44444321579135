import React from 'react';
import CTA from './CTA';
import './header.css';
import ME from '../../assets/profile_settings_Robot.svg';
import HeaderSocials from './HeaderSocials';

const Header = () => (
  <header>
    <div className="container header__container">
      <h5>Hello I'm</h5>
      <h1>Honore IRADUKUNDA</h1>
      <h5 className="text-light">Fullstack Developer</h5>
      <CTA />
      <HeaderSocials />
      <div className="me">
        <img src={ME} alt="Honore" />
      </div>
      <a href="#contact" className="scroll__down">
        Scroll Down
      </a>
    </div>
  </header>
);

export default Header;
