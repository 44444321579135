import React from 'react';
import { BsFillPatchCheckFill } from 'react-icons/bs';
import './experience.css';

const Skill = ({ title, experience }) => {
  return (
    <article className="experience__details">
      <BsFillPatchCheckFill className="experience__details-icon" />
      <div>
        <h4>{title}</h4>
        <small className="text-light">{experience}</small>
      </div>
    </article>
  );
};

export default Skill;
