import React from 'react';
import './experience.css';
import Skill from './Skill';
import skills from './skills.json';

const Experience = () => {
  return (
    <section id="experience">
      <h5>The skills I have</h5>
      <h2>Skills/Tools</h2>

      <div className="container experience__container">
        {Object.entries(skills).map(([category, skillList]) => (
          <div key={category} className={`experience__${category}`}>
            <h3>
              {category
                .replace(/_/g, ' ')
                .replace(/\b\w/g, (c) => c.toUpperCase())}
            </h3>
            <div className="experience__content">
              {skillList.map((skill) => (
                <Skill
                  title={skill.title}
                  experience={skill.experience}
                  // icon={skill.icon} // Include icon if applicable
                  key={skill.title}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Experience;
