import React from 'react';
import { BsLinkedin } from 'react-icons/bs';
import { FaGithub } from 'react-icons/fa';
import { BsTwitterX } from "react-icons/bs";

const HeaderSocials = () => {
  return (
    <div className="header__socials">
      <a
        href="https://linkedin.com/in/ihonore"
        target="_blank"
        rel="noreferrer"
      >
        <BsLinkedin />
      </a>
      <a href="https://github.com/ihonore" target="_blank" rel="noreferrer">
        <FaGithub />
      </a>
      <a href="https://x.com/ihonore01" target="_blank" rel="noreferrer">
        <BsTwitterX />
      </a>
    </div>
  );
};

export default HeaderSocials;
